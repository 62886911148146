body {
  margin: 0;
  font-family:'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
/* 
  1.563 = 25px
  1.125 = 18px
  1.000 = 16px
  0.875 = 14px
  0.813 = 13px 
  0.75 = 12px
  0.7 = 11px
  0.5 = 8px


 */
:root {
  --title: 1.563rem;
  --intermdtitle : 15px;
  --intermd1title : 16px;
  --intermd2title : 17px;
  --mdtitle: 1.125rem;
  --subtitle: 0.875rem;
  --text: 0.75rem;
  --content1: 0.875rem; 
  --content2: 0.813rem;
  --smallhint:0.7rem;
  --hintsmall:0.55rem;
  --verysmall:0.5rem;
  --placeholder : grey;
  --weight-1:500;
  --secondary1 : #6c757d;
  --connect:#4535C1;
  --connect1:#4B38A7;
  --connect2:#7969c4;
  --semibold : 600
}

.fonttitle{
  font-size: var(--title);
}
.fontmdtitle{
  font-size: var(--mdtitle);
}
.fontmd1title{
  font-size: var(--intermd1title) !important;
}
.fontmd2title{
  font-size: var(--intermd2title) !important;
}
.fontsubtitle{
  font-size: var(--subtitle);
}
.fontcontent1{
  font-size: var(--content1);
}
.fontcontent2{
  font-size: var(--content2);
}
.fonttext{
  font-size: var(--text);
}
.fonthint{
  font-size: var(--smallhint) !important;
}
.fontsmall{
  font-size: var(--verysmall);
}
.placeholdercolor {
  color: var(--placeholder);
}
.font-weight-1{
  font-weight: var(--weight-1);
}
.text-secondary1{
  color: var(--secondary1) !important;
}
.font-weight-semibold{
  font-weight: var(--semibold);
}

.scrollerhide::-webkit-scrollbar{
  display: none;  
}
.bg-connect{
  background-color: var(--connect);
}
.bg-connect-outline{
  background-color: #ffffff;
  border: 0.5px solid var(--connect);
}
.text-connect{
  color: var(--connect) !important;
}
.text-connect1{
  color: var(--connect1) !important;
}
.text-connect2{
  color: var(--connect2) !important;
}
.custom-textarea:focus {
  outline: none;
  box-shadow: none;
}
.hoverline:hover {
  z-index: unset;
}
.hoverline:hover span{
  text-decoration: underline;
}
.w-20{
  width:20% !important
}
