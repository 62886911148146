.Shimmer-background{
    height: 100px;
        background-size: cover;
        background-repeat: no-repeat;
        border-radius: 10px 10px 0px 0px;
        line-height: 2 !important;
}
#Shimmer-profile-info{
   width: 72px;
    height: 72px;
    border-radius: 50%;
    border: 2px solid #fff;
    background-color: #fff;
    margin-bottom: 18px;
    cursor: pointer;
}
.Shimmer-pro{
    width: 102px !important;
    height: 102px;
    border-radius: 50% !important;
    border: 2px solid #fff;
    margin-bottom: 18px;
    cursor: pointer;
}
.Shimmer-pro1{
    width: 102px !important;
    height: 102px;
    border-radius: 10px !important;
    border: 2px solid #fff;
    margin-bottom: 18px;
    cursor: pointer;
}

/* Center the loader */
.spinner {
    width: 60px;
    height: 60px;
    border: 6px solid #000; /* Black border */
    border-top-color: #4535C1; /* Red top border */
    border-radius: 50%;
    animation: spin 0.8s linear infinite;
    margin: 100px auto;
  }

  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }

