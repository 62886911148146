ul,
ol {
  margin: 0;
  padding: 0;
  list-style: none;
  margin-bottom: 0px !important;
}

/* Reset Bootstrap's ul and li styles for nested lists */
ul ul,
ol ol,
ul ol,
ol ul {
  margin-top: 0px;
  margin-bottom: 0px !important;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background: #eeeeee;
  font-family: "Source Sans Pro", sans-serif;
  /* propriedades para a fonte ficar mais legÃ­vel */
  text-rendering: optimizeLegibility !important;
  -webkit-font-smoothing: antialiased !important;
}

input,
button {
  outline: 0;
  font-family: "Source Sans Pro", sans-serif;
}

a {
  text-decoration: none;
}

/*************************************************************************
    HEADER
**************************************************************************/
.fa,
.fas {
  color: grey;
  font-weight: 900;
}

header#main-header {
  height: 52px;
  position: fixed;
  background: white;
  width: 100vw;
  display: flex;
  justify-content: center;
  z-index: 25;
}

header#main-header>div {
  width: 1130px;
  padding-left: 0px;
  padding-right: 0px;
  display: none;
  align-items: center;
  justify-content: space-between;
}

header#main-header>div>div {
  display: flex;
  align-items: center;
  width: 100%;
}

header#main-header>div>div>span {
  display: none;
}

header#main-header>div>div>img#header-logo {
  width: 60px;
  height: 39px;
  margin-right: 10px;
  cursor: pointer;
}

header#main-header>div>div>img#header-picture-menu {
  display: none;
}

header#main-header>div>div>span#header-picture-menu-bars {
  display: none;
}

header#main-header>div div input {
  background: rgb(247, 247, 247);
  border: none;
  border-radius: 7px;
  font-size: 14px;
  height: 34px;
  max-width: 280px;
  flex: 1;
  color: #4535C1 !important;
  padding-left: 35px;
}

header#main-header>div>div div {
  display: flex;
  flex: 1;
  /* max-width: 280px; */
  position: relative;
}

header#main-header>div>div div input:focus {
  background: #fff;
}

header#main-header>div div div span#search-icon {
  position: absolute;
  left: 10px;
  top: 12px;
  font-size: 12px;
  color: #4535C1;
}

header#main-header>div nav {
  margin-left: 17px;
}

header#main-header>div nav ul {
  display: flex;
  align-items: flex-end;
  gap: 20px;
}

header#main-header>div nav ul li {
  color: grey;
  display: inline-block;
  height: 57px;
}

header#main-header>div nav ul li div {
  display: flex;
  align-items: center;
  /* flex-direction: row; */
  flex-direction: column;
  justify-content: flex-end;
  /* width: 102px; */
  font-size: 20px;
  height: 100%;
  padding-bottom: 3px;
  cursor: pointer;
  transition: color 0.3s;
}

header#main-header>div nav ul li div:hover {
  color: #fff;
}

header#main-header>div nav ul li div.selected {
  border-bottom: solid 2px #fff;
  color: #fff;
}

header#main-header>div nav ul li div#home {
  position: relative;
}

header#main-header>div nav ul li div#home::after {
  position: absolute;
  right: 23px;
  top: 5px;
  width: 13px;
  height: 13px;
  background: #d11124;
  border: 2px solid #4535C1;
  content: "";
  border-radius: 50%;
}

header#main-header>div nav ul li div.active span.nav-item-text,
header#main-header>div nav ul li div.active span {
  color: #4535C1;
}

header#main-header>div nav ul li div.active {
  color: #4535C1;
  border-bottom: 2px solid #4535C1;
}

header#main-header>div nav ul li div#right-border {
  border-right: solid 1px rgba(255, 255, 255, 0.3);
}

header#main-header>div nav ul li div img {
  height: 21px;
  /* height: 1.4rem; */

  width: 21px;
  /* width: 1.5rem; */
  /* border-radius: 50%; */
  border: 1px solid white;
}

header#main-header>div nav ul li div a {
  font-size: 13px;
  width: 100px;
  text-align: center;
  padding-bottom: 7px;
  color: #d3d2a1;
  margin-left: 20px;
  line-height: 1.2;
}

header#main-header>div nav ul li div a:hover {
  text-decoration: underline;
}

header#main-header>div nav ul li div span.nav-item-text {
  font-size: var(--content2);
  color: #676767;
}

.nav-item-text {
  font-size: var(--content2);
  color: #676767;
}

header#main-header>div nav ul li div span.nav-item-text span {
  margin-left: 4px;
}

.btn-outline-connect-2 {
  font-weight: 700;
  padding: 2px 6px !important;
  font-size: 10px;
  background-color: white;
  color: #4535C1;
  border-color: #4535C1;
}

.more-option-container {
  margin-left: 10px;
  border: 1px solid lightgray;
  align-items: center;
  justify-content: center;
  display: flex;
  padding: 10;
  width: 25px;
  height: 25px;
  border-radius: 90px;
}

@media only screen and (max-width: 600px) {
  div.feed_doublecontainer .page-main-section {
    margin-top: 5px !important;
  }
}

/***************************************************************************
    header > HEADER MEDIA QUERY
/**************************************************************************/
@media only screen and (max-width: 1047px) {
  header#main-header>div nav ul li div {
    width: 45px;
    justify-content: center;
  }

  header#main-header>div nav ul li div.selected {
    border-style: none;
  }

  header#main-header>div nav ul li div span.nav-item-text {
    display: none;
  }

  header#main-header>div nav ul li div a {
    padding-bottom: 0;
  }

  header#main-header>div nav ul li div#home::after {
    right: 5px;
    top: 10px;
  }
}

@media only screen and (max-width: 700px) {
  header#main-header>div {
    padding-right: 14px;
  }

  header#main-header>div nav {
    display: none;
  }

  header#main-header>div>div>img#header-logo {
    display: block;
  }

  header#main-header>div>div>img#header-picture-menu {
    display: block;
    height: 38px;
    width: 38px;
    border-radius: 0%;
    border: 2px solid #fff;
    /* box-shadow: inset 0 1.5px 3px 0 rgba(0, 0, 0, 0.15),
      0 1.5px 3px 0 rgba(0, 0, 0, 0.15); */
    background-color: white;
    cursor: pointer;
    margin-right: 25px;
  }

  header#main-header>div>div>span#header-picture-menu-bars {
    display: block;
    position: absolute;
    left: 25px;
    top: 26px;
    color: #4535C1;
    background: #fff;
    font-size: 10px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: #f6f6f6;
    text-align: center;
  }

  header#main-header>div>div>span#header-picture-menu-bars span {
    margin-top: 5px;
  }

  header#main-header>div>div>span {
    display: block;
    color: white;
    width: 38px;
    font-size: 26px;
    cursor: pointer;
    transition: color 0.3s;
    margin-left: 23px;
  }

  header#main-header>div>div>span:hover {
    color: #fff;
  }

  header#main-header>div>div div {
    max-width: 100%;
    flex-shrink: 1;
    width: 0;
  }

  header#main-header>div div input {
    max-width: 100%;
    width: 0;
  }

  header#main-header>div div input {
    max-width: 100%;
    width: 0;
  }
}

/*************************************************************************
    MAIN feed_container
**************************************************************************/
div.feed_container {
  max-width: 1130px;
  margin: 0 auto;
  padding: 0 0px;
  grid-template-areas:
    "ads ads ads"
    "left-aside main-section right-aside";
  grid-template-rows: 65px 1fr;
  grid-template-columns: 240px 1fr 260px;
  display: none;
}

div.feed_doublecontainer {
  max-width: 1130px;
  margin: 0 auto;
  padding: 0 0px;
  grid-template-areas:
    "ads ads"
    "main-section1 right-aside1";
  grid-template-rows: 65px 1fr;
  grid-template-columns: 1fr 300px;
  display: none;
}
div.feed_doublecontainer.reverse {
  max-width: 1130px;
  margin: 0 auto;
  padding: 0 0px;
  grid-template-areas:
    "ads ads"
    "main-section1 right-aside1";
  grid-template-rows: 65px 1fr;
  grid-template-columns:300px 1fr;
  display: none;
}

/**************************************************************************
    main feed_container > ADS SECTION
**************************************************************************/
div.feed_doublecontainer section#ads {
  grid-area: ads;
  display: flex;
  align-items: flex-end;
}

div.feed_container section#ads {
  grid-area: ads;
  display: flex;
  align-items: flex-end;
}

section#ads div {
  padding: 16px 0;
  height: 50%;
  flex: 1;
  text-align: center;
  font-size: 15px;
  font-family: helvetica, arial, sans-serif;
}

section#ads div a {
  cursor: pointer;
  font-weight: bold;
}

section#ads div a:hover {
  text-decoration: underline;
}

section#ads div span#ad-title {
  color: #0084bf;
}

section#ads div span#ad-subtitle {
  padding-left: 3px;
  color: #434649;
}

section#ads div span#ad {
  font-size: 13px;
  padding-left: 3px;
  color: rgba(82, 67, 67, 0.55);
}

/**************************************************************************
    main feed_container > LEFT ASIDE
**************************************************************************/
.feed_container>div#left-aside-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.feed_container aside#left-aside {
  grid-area: left-aside;
  height: auto;
  width: 100%;
}

.feed_container aside#left-aside>div {
  background: #fff;
  border-radius: 7px;
}

/**************************************************************************
    main feed_container > left aside > PROFILE CARD
**************************************************************************/
aside#left-aside div#profile-card {
  height: auto;
  margin-bottom: 8px;
  /* padding-bottom: 40px; */
  display: flex;
  flex-direction: column;
}

.profile-bold {
  font-weight: bolder;
  font-size: 12px;
}

aside#left-aside div#profile-card div#profile-info {
  height: auto;
  width: 100%;
  background: transparent;
  padding: 5px 1px 0px;
  /* border-bottom: 1px solid rgba(0, 0, 0, 0.15); */
  /* margin: -49px auto 12px; */
  margin: 12px auto 12px;
  display: flex;
  row-gap: 8px;
  flex-direction: column;
  align-items: start;
}

aside#left-aside div#profile-card div#profile-info img {
  /* width: 72px;
  height: 72px; */
  height: 80px;
  width: 80px;
  object-fit: cover;
  border-radius: 50%;
  border: 2px solid #fff;
  background-color: #fff;
  /* margin-bottom: 5px; */
  cursor: pointer;
}

aside#left-aside div#profile-card div#profile-info strong {
  font-size: var(--subtitle);
  margin-bottom: 5px;
  cursor: pointer;
}

aside#left-aside div#profile-card div#profile-info small {
  font-size: var(--content2);
  color: rgba(0, 0, 0, 0.6);
}

aside#left-aside div#profile-card div#profile-links {
  font-size: 14px;
  padding-top: 6px;
  display: flex;
  flex-direction: column;
}

aside#left-aside div#profile-card div#profile-links a {
  display: flex;
  justify-content: space-between;
  padding: 3px 12px;
}

aside#left-aside div#profile-card div#profile-links a:hover {
  text-decoration: underline;
  color: rgba(0, 0, 0, 0.6);
}

aside#left-aside div#profile-card div#profile-links a span {
  color: rgba(0, 0, 0, 0.6);
  font-weight: bold;
  font-size: 13px;
}

aside#left-aside div#profile-card div#profile-links a span.profile-number {
  color: #0073b1;
}

aside#left-aside div#profile-card>span {
  font-size: 12px;
  /* padding: 0 12px; */
  font-weight: bold;
  color: rgba(0, 0, 0, 0.9);
}

aside#left-aside div#profile-card>a {
  font-size: 13px;
  margin-top: 15px;
  line-height: 15px;
  color: rgba(0, 0, 0, 0.6);
  display: flex;
  flex-direction: column;
  align-items: center;
}

aside#left-aside div#profile-card>a:hover {
  text-decoration: underline;
}

/**************************************************************************
    main feed_container > MAIN SECTION
**************************************************************************/
.feed_container>div#main-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 100px;
}

.feed_doublecontainer>div#main-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 100px;
}

.feed_container>div#main-wrapper1 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.feed_doublecontainer>div#main-wrapper1 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

div.feed_container main#main-section {
  grid-area: main-section;
  margin: 0 auto;
  width: 580px;
}

div.feed_doublecontainer main#main-section {
  grid-area: main-section;
  /* margin: 0 10px; */
  width: 100%;
}

/**************************************************************************
    main feed_container > main section > SHARE BOX & FEED SORT
**************************************************************************/
main#main-section div#share-box {
  background: #fff;
  border-radius: 10px;
  margin-top: 1px;
  display: flex;
  flex-direction: column;
}

main#main-section div#share-box div#button-box {
  display: flex;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}

main#main-section div#share-box div#button-box .fas {
  font-size: 18px;
}

main#main-section div#share-box div#button-box button {
  background: #fff;
  border: 0;
  color: #696c6f;
  border-right: 1px solid rgba(0, 0, 0, 0.15);
  padding: 26px 26.5px;
  cursor: pointer;
}

main#main-section div#share-box div#button-box button#btn-post {
  width: 100%;
  text-align: left;
  padding: 26px 17px;
}

main#main-section div#share-box div#button-box button#btn-post:hover {
  color: #0073b1;
}

main#main-section div#share-box div#button-box button#btn-post:hover span#btn-text {
  text-decoration: underline;
}

main#main-section div#share-box div#button-box button#btn-post span#btn-text {
  font-size: 16px;
  font-weight: bold;
  padding-left: 6px;
}

main#main-section div#share-box div#button-box button#btn-document {
  border-right: 0;
  padding: 26px 30px;
}

main#main-section div#share-box div#button-box button#btn-picture:hover,
main#main-section div#share-box div#button-box button#btn-video:hover,
main#main-section div#share-box div#button-box button#btn-document:hover {
  background: #f3f6f8;
}

main#main-section div#feed-sort {
  display: flex;
  align-items: center;
  margin: 6px 0;
}

main#main-section div#feed-sort hr {
  flex: 1;
  border: 0;
  display: block;
  height: 1px;
  border-top: 1px solid rgba(0, 0, 0, 0.15);
}

main#main-section div#feed-sort button {
  border: 0;
  background: transparent;
  margin-left: 15px;
  padding-bottom: 4px;
  cursor: pointer;
}

main#main-section div#feed-sort button span {
  color: rgba(0, 0, 0, 0.6);
}

main#main-section div#feed-sort button span.fas {
  font-size: 18px;
  padding: 0 2px;
}

/**************************************************************************
    main feed_container > main section > FEED POSTS
**************************************************************************/
main#main-section article {
  display: flex;
  flex-direction: column;
  background: #fff;
  border-radius: 10px;
  margin-bottom: 8px;
}

main#main-section article div#post-author {
  display: flex;
  padding: 1.2rem 0.8rem 0;
}

main#main-section article div#post-author a {
  flex: 1;
  font-size: 13px;
  color: rgba(0, 0, 0, 0.6);
}

main#main-section article div#post-author>div>div {
  cursor: pointer;
  border-radius: 50%;
  height: 32px;
  width: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}

main#main-section article div#post-author>div>div>span {
  padding: 2px;
  font-size: 4px;
}

main#main-section article div#post-author a>div {
  display: flex;
}

main#main-section article div#post-author a>div img {
  width: 55px;
  height: 55px;
  border-radius: 50%;
  border: 2px solid transparent;
  margin-right: 6px;
}

main#main-section article div#post-author a>div>div {
  display: flex;
  flex-direction: column;
  margin-top: 0px;
  /* width: 70%; */
}

main#main-section article div#post-author a>div>div>span {
  line-height: 16px;
  /* margin-top: 4px; */
  margin-left: 1px;
  font-size: var(--text);
}

main#main-section article div#post-author a>div>div div strong {
  color: #000;
  font-size: 15px;
}

main#main-section article div#post-author a>div>div div strong:hover {
  color: #0073b1;
}

main#main-section article div#post-author a>div>div div span span {
  font-size: 14px;
  font-weight: bold;
}

main#main-section article div#post-data p {
  padding: 1rem 1.6rem 0;
}

main#main-section article div#post-data p span {
  color: #0073b1;
  font-weight: bold;
  cursor: pointer;
}

main#main-section article div#post-data p a {
  color: #0073b1;
  font-weight: normal;
  font-size: var(--content1);
}

main#main-section article div#post-data img {
  width: 100%;
  cursor: pointer;
  /* border-bottom: 1px solid #e6e9ec; */
  /* border-top: 1px solid #e6e9ec; */
}

main#main-section article div#post-interactions div#interactions-amount {
  margin: 7px 16px;
  border-bottom: 1px solid #e6e9ec;
  padding-bottom: 10px;
  display: flex;
  align-items: center;
}

main#main-section article div#post-interactions div#interactions-amount span.fas {
  font-size: 8px;
  padding: 3px;
  margin-right: 3px;
  border-radius: 50%;
  color: #f3f6f8;
  text-shadow: -1px -1px 0 rgba(0, 0, 0, 0.3), 1px -1px 0 rgba(0, 0, 0, 0.3),
    -1px 1px 0 rgba(0, 0, 0, 0.3), 1px 1px 0 rgba(0, 0, 0, 0.3);
  cursor: pointer;
}

main#main-section article div#post-interactions div#interactions-amount span#amount-info {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.6);
  margin-left: 3px;
  cursor: pointer;
}

main#main-section article div#post-interactions div#interactions-amount span#amount-info span {
  font-size: 14px;
  font-weight: bold;
}

main#main-section article div#post-interactions div#interactions-amount span#like-icon {
  background: #0092e0;
}

main#main-section article div#post-interactions div#interactions-amount span#heart-icon {
  background: #f67373;
}

main#main-section article div#post-interactions div#interactions-btns {
  margin: 4px 10px;
  display: flex;
  align-items: center;
  justify-content: start;
  /* border-top: 0.5px solid lightgrey; */
}

main#main-section article div#post-interactions div#interactions-btns button {
  border: 0;
  background: #fff;
  color: rgba(0 0 0 /70%);
  font-weight: 500;
  font-size: 12px;
  padding: 4px 10px;
  height: 45px;
  margin-right: 5px;
  cursor: pointer;
  /* flex-direction: column; */
  border-radius: 10px;
  display: flex;
  align-items: center;
}

main#main-section article div#post-interactions div#interactions-btns button:focus {
  outline: none;
}

main#main-section article div#post-interactions div#interactions-btns button:hover {
  background: #f1f1f1;
  color: rgba(0, 0, 0, 0.8);
  border-radius: 5px;
}

main#main-section article div#post-interactions div#interactions-btns button span.far {
  font-size: 20px;
  margin-right: 5px;
}

main#main-section article div#post-interactions div#interactions-btns button span.fa-thumbs-up {
  margin-bottom: 4px;
}

main#main-section article div#post-interactions div#interactions-btns button span.fa-share-square {
  font-size: 18px;
}

/**************************************************************************
    main feed_container > RIGHT ASIDE
**************************************************************************/
div.feed_container aside#right-aside {
  grid-area: right-aside;
  /* background-color: #fff; */
  /* border: 1px solid #DCDCDC; */
  border-radius: 10px;
  height: auto;
}

/**************************************************************************
    main feed_container > right aside > RECOMMENDATIONS SECTION
**************************************************************************/
aside#right-aside div#rec-section {
  height: 273px;
  padding: 16px 0;
  border-bottom: 1px solid #dcdcdc;
}

aside#right-aside div#rec-section div header {
  font-size: 18px;
  padding: 13px 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

aside#right-aside div#rec-section div header span.fas {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.6);
  position: relative;
}

aside#right-aside div#rec-section div header span,
.activeword {
  color: rgb(138, 197, 63) !important;
}

aside#right-aside div#rec-section div header span.fas>span {
  display: none;
  position: absolute;
  top: -4px;
  right: 18px;
  font-size: 20px;
  color: #fff;
  text-shadow: -1px 0 #fff, 0 1px #dcdcdc, 1px 0 #dcdcdc, 0 -1px #dcdcdc;
}

aside#right-aside div#rec-section div header span.fas div#float-info {
  display: none;
  position: absolute;
  top: -22px;
  right: 33px;
  height: 60px;
  width: 320px;
  padding: 13px;
  font-family: "Source Sans Pro", sans-serif;
  font-weight: normal;
  color: #000;
  background-color: #fff;
  border: 1px solid #dcdcdc;
  border-radius: 10px;
  line-height: 1.2;
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.25);
}

aside#right-aside div#rec-section div header span.fas div#float-info a {
  color: #0073b1;
  font-weight: bold;
}

aside#right-aside div#rec-section div header span.fas div#square {
  position: absolute;
  top: -22px;
  right: 10px;
  height: 60px;
  width: 30px;
  background: transparent;
  min-height: 1px;
}

aside#right-aside div#rec-section>div>div {
  padding-bottom: 6px;
}

aside#right-aside div#rec-section>div>div>div {
  display: flex;
  align-items: center;
  padding: 0 10px 3px;
}

aside#right-aside div#rec-section>div>div>div img {
  width: 48px;
  height: 48px;
  border: 4px solid transparent;
  border-radius: 10px;
  margin-right: 8px;
  cursor: pointer;
}

aside#right-aside div#rec-section>div>div>div>div {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-right: 5px;
}

aside#right-aside div#rec-section>div>div>div>div strong {
  font-size: 14px;
  padding-bottom: 3px;
  cursor: pointer;
}

aside#right-aside div#rec-section>div>div>div>div small {
  font-size: 13px;
  color: rgba(0, 0, 0, 0.6);
  line-height: 14px;
  cursor: pointer;
}

aside#right-aside div#rec-section>div>div>div button {
  width: 51px;
  height: 16px;
  padding: 15px 36px;
  border-radius: 7px;
  background-color: rgb(138, 197, 63);
  cursor: pointer;
  border: none;
  display: flex;
  align-items: center;
  color: white;
  justify-content: center;
  transition: background-color 0.2s;
}

aside#right-aside div#rec-section>div>a {
  color: #0073b1;
  font-size: 16px;
  margin: 13px;
}

aside#right-aside div#rec-section>div>a:hover {
  color: #016194;
}

/***************************************************************************
    feed_container > feed_container MEDIA QUERY
/**************************************************************************/
@media only screen and (max-width: 1180px) {
  div.feed_container {
    grid-template-areas:
      "ads ads"
      "left-aside main-section";
    grid-template-rows: 100px 1fr;
    grid-template-columns: 1fr 2fr;
  }

  div.feed_container aside#right-aside {
    display: none;
  }
}

@media only screen and (max-width: 861px) {
  div.feed_container {
    grid-template-areas:
      "."
      "main-section ";
    grid-template-rows: 62px 1fr;
    grid-template-columns: 1fr;
  }

  .feed_container>div#main-wrapper {
    align-items: center;
  }

  div.feed_container section#ads {
    display: none;
  }

  div.feed_container aside#left-aside {
    display: none;
  }
}

@media only screen and (max-width: 660px) {
  div.feed_container {
    padding: 0;
  }

  div.feed_doublecontainer {
    padding: 0;
  }

  div.feed_container main#main-section {
    max-width: 100vw;
    width: 100%;
    margin: 0;
  }

  div.feed_doublecontainer main#main-section {
    max-width: 100vw;
    width: 100%;
    margin: 0;
    margin-top: 10px !important;
    /* margin-top: 45px !important; */
  }

  main#main-section div#share-box div#button-box button {
    display: none;
  }

  main#main-section div#share-box div#button-box button#btn-post {
    display: block;
    padding: 20px 17px;
    border-right: 0;
  }

  main#main-section div#feed-sort button {
    margin-right: 10px;
  }
}

div#loading-page>div {
  position: fixed;
  top: 25%;
  left: calc(50% - 32px);
  display: flex;
  flex-direction: column;
  align-items: center;
}

div#loading-page img {
  width: 50px;
  height: 50px;
}

div#dot-loader {
  padding-left: 2px;
  width: 64px;
  height: 64px;
}

/* css loader from loading.io */
.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;
}

.lds-ellipsis div {
  position: absolute;
  top: 27px;
  width: 11px;
  height: 11px;
  border-radius: 50%;
  background: #0073b1;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.lds-ellipsis div:nth-child(1) {
  left: 6px;
  animation: lds-ellipsis1 0.6s infinite;
}

.lds-ellipsis div:nth-child(2) {
  left: 6px;
  animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(3) {
  left: 26px;
  animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(4) {
  left: 45px;
  animation: lds-ellipsis3 0.6s infinite;
}

@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(0);
  }
}

@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }

  100% {
    transform: translate(19px, 0);
  }
}

/***************************************************************************
    GLOBAL CLASSES
/**************************************************************************/
.letter-spacing {
  letter-spacing: 0.6px;
}

.font-16 {
  font-size: 16px !important;
}

.position-fixed {
  position: fixed;
  top: 60px;
}

.right-aside-fixed {
  background: #fff;
  border: 1px solid #dcdcdc;
  width: 312px;
  height: 440px;
}

.red-placeholder::placeholder {
  color: red;
}

.full-width {
  margin-right: 10px;
  height: 250px;
  object-fit: cover;
}

.linked-input {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
}

.input {
  display: flex;
  align-items: center;
}

.inputs::-webkit-scrollbar {
  display: none;
}

.linked-input {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 3px 86px;
}

.middle-pic {
  width: 50px;
  border-radius: 50%;
  margin-right: 10px;
}

.name {
  font-weight: 600;
  font-size: 14px;
  margin-bottom: 2px;
}

.post-about {
  display: flex;
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 10px 20px;
}

.selected-photos {
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
  padding: 10px;
  column-gap: 20px;
}

.selected-photo {
  width: 120px;
  height: 120px;
  object-fit: cover;
  cursor: pointer;
  border-radius: 10px;
}

.containers {
  width: auto;
  height: auto;
  padding: 15px;
  position: relative;
  border-radius: 0px 5px 8px;
  background-color: #FAFAFA;
}

h6 {
  padding-left: 5px;
}

.action {
  font-size: 11px;
  padding-top: 7px;
  padding-left: 9px;
  margin-bottom: 0px;
}

.actionmodal {
  font-size: 10px;
  padding-top: 7px;
  padding-left: 9px;
}

.actions {
  font-size: 14px;
  padding-top: 10px;
  padding-left: 9px;
}

.newstext {
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: 0em;
  color: #4535C1;
}

.newsbox {
  background: #fff;
  border-radius: 7px;
  border: 1px solid #dcdcdc;
  overflow: hidden;
}

.column-gap-1 {
  column-gap: 10px;
}

.column-gap-2 {
  column-gap: 20px;
}

.newsfeed {
  font-size: 10px !important;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.02em;
  text-align: left;
  color: #000000;
}

.newsfeed1 {
  font-weight: 400;
  line-height: 12px;
  /* letter-spacing: 0.02em; */
  text-align: left;
  color: #6b6868;
}

.blog-img {
  width: 45px;
  height: 45px;
  border: 1px solid #ECECEC;
  border-radius: 5px;
  object-fit: contain;
}

.blog-img-circle {
  width: 45px;
  height: 45px;
  max-width: 45px;
  margin-right: 10px;
  border: 1px solid #ECECEC;
  border-radius: 50%;
  object-fit: contain;
}

.blog-img1 {
  width: 40px;
  height: 40px;
  /* margin:auto; */
  border: 1px solid #ECECEC;
  border-radius: 5px;
  object-fit: contain;
}

.moreoptions span {
  font-size: 12px;
  font-weight: 400;
  line-height: 15px;
  letter-spacing: 0.02em;
  text-align: justify;
  color: #000000;
}

.row-gap-1 {
  row-gap: 10px;
}

.row-gap-2 {
  row-gap: 20px;
}

.networksmall {
  font-size: 10px;
  font-weight: 400;
  line-height: 12px;
  letter-spacing: 0em;
}

.manage {
  font-size: 10px;
  font-weight: 500;
  line-height: 12px;
  letter-spacing: 0em;
  color: #0038ff;
}

.close {
  position: absolute;
  right: 10px;
  color: white !important;
}

.blackback {
  background-color: #000 !important;
  border-radius: 5px;
}

.blackback1 {
  background-color: #fff !important;
  border-radius: 5px;
}

.modal-lg {
  max-width: 950px !important;
  height: 430px;
}

.modal-lgg {
  max-width: 50vw !important;
  height: 90vh;
  margin-top: 0%;
}

.modal-lg {
  max-width: 950px !important;
  height: 430px;
}

.modal-lgauto {
  max-width: 950px !important;
  height: auto;
  min-height: 450px !important;
}

.modal-lg1 {
  max-width: 950px !important;
  height: 550px;
}

.modal-md {
  margin-top: 150px;
  max-width: 750px !important;
  height: 430px;
}

.modal-mdsm {
  margin-top: 150px;
  max-width: 450px !important;
  height: auto;
}

.modal-md1 {
  margin-top: 100px;
  max-width: 750px !important;
  /* height: 430px; */
}
.modal-md1s {
  margin-top: 50px;
  max-width: 750px !important;
  /* height: 430px; */
}

.modal-mdmd {
  max-width: 750px !important;
  margin-top: 60px;
  height: 430px;
}

.modal-mdd {
  margin-top: 40px;
  max-width: 550px !important;
  min-height: 350px;
}

.modal-content {
  border-radius: 5px;
  height: 100%;
  width: 100% !important;
  justify-content: center;
  /* align-items: center; */

}

.modal-dialog {
  max-width: 70vw;
}

.flex-column1 {
  text-align: center;
  /* display: flex !important;
  justify-content: center !important; */
}

.ReactCrop__child-wrapper {
  display: flex !important;
  /* height: 400px; */
  justify-content: center !important;
}

.ReactCrop__crop-selection:not(.ReactCrop--no-animate .ReactCrop__crop-selection) {
  background-image: none !important;

}

.border-rad2-2 {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.modal-dialog {
  display: flex;
  justify-content: center;
}

.modal {
  top: 1%;
  height: auto !important;

  /* display: flex !important;
  align-items: center;
  justify-content: center; */

}

.modaldash {
  top: 2%;
  height: 100vh !important;
  overflow-y: scroll;
}

.mode {
  top: 1%
}

.modaltop {
  top: 1%;

}

.modal-texting {
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
}

.hastags span {
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  color: #008294;
}

#interaction-btns button {
  border: 0;
  /* background: #fff; */
  background: #00000000;
  /* color: rgba(0, 0, 0, 0.6); */
  color: #fff;
  font-weight: bold;
  font-size: 14px;
  padding: 10px 10px;
  height: auto;
  cursor: pointer;
  flex-direction: column;
  display: flex;
  align-items: center;
}

#interaction-btns {
  margin: 4px 10px;
  display: flex;
  justify-content: space-around;
}

#interaction-btns button:focus {
  outline: none;
}

#post-authors a>div img {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 2px solid transparent;
  margin-right: 6px;
}

.interactiontext {
  font-size: 12px;
}

#post-authors {
  display: flex;
  /* padding: 14px 14px 0; */
}

.modalcontaine {
  width: 100%;
  height: auto;
  padding: 5px 15px;
  border: 1px solid rgb(173, 173, 173);
  /* margin: 14px;
    padding: 5px; */
  position: relative;
  /* left: 57px; */
  /* bottom: 95px; */
  border-radius: 0px 5px 5px;
  background-color: #00000000;
}

.scrollhide::-webkit-scrollbar {
  display: none;
}

.step {
  font-size: var(--content2);
  font-weight: 600;
  line-height: 9.68px;
  letter-spacing: 0.05em;
  text-align: left;
}

.completebtn {
  width: auto;
  height: auto;
  border: none;
  border-radius: 25px;
  background-color: #4535C1;
  color: white;
  font-size: var(--content1);
  font-weight: 600;
  line-height: 12.1px;
  letter-spacing: 0.05em;
  text-align: left;
}

.completebtn:focus {
  outline: none;
}

.crossdiv {
  position: relative;
  top: 13px;
  left: 87%;
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.reactimage:hover {
  width: 35px;
  padding-bottom: 35px;
}

.buttonback:hover {
  background: none !important;
}


.line-tag-ver {
  position: relative;
  border-left: 4px solid #4535C1;
  height: 50px;
  transform: rotate(0deg);
  margin-top: 0px;
  left: 8px;
  z-index: 0;
}

.step-item:first-child .line-tag {
  width: 0px;
  transform: rotate(0deg);
}

.step-item:last-child .line-tag {
  height: 0;
}

.hovering:hover {
  /* box-shadow: 0px 1px 1px 0px grey; */
  background-color: #676767;
  color:#ffffff !important;
  
}

.act-hover {
  background-color: white;
}

.act-hover span {
  color: black
}

.act-hover:hover {

  box-shadow: 0px 0px 1px 1px grey;
}

.activated {
  background-color: #4535C1;
  border-color: #4535C1;
}

.activated span {
  color: white;

}


.background {
  background-color: #ffffff;
  box-shadow: 0px 4px 4px 0px #A2A2A240;
}

.background1 {
  background: white;
  cursor: pointer;
}

.chat-container {
  max-width: 100%;
  overflow: hidden;
  min-height: 45vh;
  overflow-y: hidden;
}

.chat-messages {
  max-height: auto;
  overflow-y: auto;
  padding: 10px;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.user-message {
  background-color: #4535C1;
  color: white;
  align-self: flex-end;
  border-radius: 14px 14px 0px 14px;
  font-size: var(--content1);
  line-height: 17px;
  max-width: 70%;
  line-break: anywhere;
}

.bot-message {

  background-color: #fff;
  color: grey;
  align-self: flex-start;
  border-radius: 0px 14px 14px 14px;
  font-size: var(--content1);
  line-height: 17px;
  max-width: 70%;
  line-break: anywhere;
}

.usermessagetime {
  font-size: var(--smallhint);
  font-weight: 500;
  line-height: 12px;
  letter-spacing: 0em;
  text-align: left;
}

.botmessagetime {
  font-size: var(--smallhint);
  font-weight: 500;
  line-height: 12px;
  letter-spacing: 0em;
  text-align: right;
}

.sendbutton {
  height: 40px;
  width: 40px;
  border: none;
  background-color: #4535C1;
  border-radius: 50%;
}

.chat-messages::-webkit-scrollbar {
  display: none;
}

.inputback {
  background-color: #e7e7e7;
  border-radius: 25px;
}

.imagesize {
  width: 96%;
  /* height: 450px; */
  /* height: 100%; */
  height: 65vh;
}

.modalsize {
  width: 100%;
  max-height: 450px;
  overflow-y: scroll
}

.modalsize1 {
  width: 100%;
  max-height: 100%;
  overflow-y: scroll
}

.iconsize {
  width: 50px;
  height: 50px;
}

@media screen and (max-width:540px) {

  div.feed_container {
    grid-template-areas:
      "."
      "main-section ";
    grid-template-rows: 1fr;
    grid-template-columns: 1fr;
  }

  div.feed_doublecontainer {
    grid-template-areas:
      "."
      "main-section ";
    grid-template-rows: 1fr;
    grid-template-columns: 1fr;
  }

  .modal-dialog {
    max-width: 100vw !important;
    margin: 0px
  }

  .border-rad2-2 {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
  }

  .modal-lg {
    max-width: 950px !important;
    height: 680px;
  }

  .imagesize {
    width: 95%;
    height: 400px;
  }

  .modalsize {
    width: 100%;
    max-height: 680px;
    overflow-y: hidden
  }

  .smscroll {
    max-height: 200px;
    overflow-y: scroll;

  }

  .iconsize {
    width: 25px;
    height: 25px;
  }

  .px-sm-1 {
    padding-left: 0.25rem !important;
    padding-right: 0.25rem !important;
  }

  .py-sm-3 {
    padding-top: 0.75rem !important;
    padding-bottom: 0.75rem !important;
  }
  div.feed_doublecontainer.reverse {
    max-width: 1130px;
    margin: 0 auto;
    padding: 0 0px;
    grid-template-areas:
      "ads ads"
      "main-section1 right-aside1";
    grid-template-rows: 65px 1fr;
    grid-template-columns: 1fr;
    display: none;
  }

}

.border-secondary1 {
  border: 1px solid #6c6c6c !important;
  color: #000
}

.border-secondary1:hover {
  color: #000 !important;
}

.bg-dark1 {
  background-color: #000;
}

.rounded-01 {
  border-radius: 4px !important;
}

.ecllipse {
  white-space: 'nowrap';
  text-overflow: 'ellipsis';
  width: '100%';
  overflow: 'hidden'
}

.card-content {
  display: grid;
  grid-template-columns: auto 1fr auto;
  /* 3 columns: image, title, button */
  gap: 10px;
  /* space between items */
  align-items: center;
  /* center items vertically */
}

.card-content1 {
  display: grid;
  grid-template-columns: auto minmax(0, 600px) auto;
  /* 3 columns: image, title, button */
  gap: 15px;
  /* space between items */
  align-items: center;
  /* center items vertically */
}

@media screen and (min-width:768px) {

  .w-lg-85 {
    width: 85%
  }

  .w-lg-50 {
    width: 50% !important
  }
}


/* Post Card */

.post-modal-container {
  background: none;
  width: 60vw !important;
}

.post-madal-boday {
  width: 60vw;
}

input[type="checkbox"]:checked {
  background-color: #4535C1 !important;
  border-color: #4535C1;
}

.btn-back:hover {
  background: white;
}

@media screen and (max-width:600px) {
  .post-modal-container {
    width: 90vw !important;
  }

  .post-madal-boday {
    width: 90vw;
  }

  .post-text-area-container {
    min-height: 10vh;
  }

  .post-option-menu {
    right: 1%;
  }
}
.w-90{
  width: 90%;
}
.border-bottom-light{
  border-bottom: 0.7px solid grey;
}